<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Modifica Task Project</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Modifica Task Project</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <form @submit="submit">
          <label>Nome:</label>
          <ion-input id="input" v-model="taskproject.name"></ion-input>
          <label>Descrizione:</label>
          <ion-input id="input" v-model="taskproject.description"></ion-input>
          <ion-button type="submit">MODIFICA</ion-button> 
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, 
    IonHeader,
    IonInput, 
    IonMenuButton, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonInput, 
      IonMenuButton,
      IonPage,
      IonTitle,
      IonToolbar
    },
    data: function(){
      return{
        taskproject:{
          name: '',
          description:'',
        }
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();
        if(this.name!='' || this.name!=null){
          this.axios.post(this.apiUrl + 'update-task-project', {taskproject:this.taskproject}, this.headers)
          .then(async (response) => {
            if(response.data.status_code==200){
              const toast = await toastController
              .create({
                  color: 'success',
                  message: response.data.message,
                  duration: 2000
            })
            this.$router.push('/folder/task-projects-list');
            return toast.present();
            }
          })
        }
      },
      deleteTaskProject(){
        this.axios.post(this.apiUrl + 'delete-task-project', {taskproject:this.taskproject}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.taskproject=response.data.message              
          }
        })
      },
      getTaskProjectInfo(){
        this.axios.post(this.apiUrl + 'get-task-project-info', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          this.taskproject=response.data.taskproject;
        })
      }
    },
    ionViewWillEnter: function(){
      this.getTaskProjectInfo();
    }
  }
</script>
<style scoped>
  #input{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
  form{
    padding:10px;
  }
</style>